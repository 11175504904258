import { NAV_CONFIG } from "@/constants"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import classNames from "classnames"
import React from "react"
import { NavLink, BrowserRouter as Router } from "react-router-dom"

export const getAuthorizedNavItems = (role, userPermissions, feature_flags) => {
  const enabled_new_dashboard = feature_flags.new_school_admin_dashboard_enabled;

  const navConfig = NAV_CONFIG[role];
  if (!navConfig) return [];

  const shouldUseNewDashboard = (role === "school_admin" && enabled_new_dashboard);
  const effectiveRole = shouldUseNewDashboard ? "school_admin_new_dashboard" : role;
  return NAV_CONFIG[effectiveRole].filter(navItem => !navItem.permission || userPermissions[navItem.permission])
}

const NavbarLinks = ({ currentUser, hasNewMessage, hasSessionRequests, hasInterviewRequests }) => {
  const userRole = currentUser?.role
  const { permissions: userPermissions, feature_flags } = currentUser

  const newEvents = { hasNewMessage, hasSessionRequests, hasInterviewRequests }
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const navItems = getAuthorizedNavItems(userRole, userPermissions, feature_flags)

  return (
    <Router className="navbar-links">
      <div
        className={classNames({
          "mobile-nav": isMobile,
          "main-nav": !isMobile,
        })}
      >
        {navItems.map(({ path, label, hasNew }) => (
          <React.Fragment key={path}>
            <NavLink to={path} reloadDocument relative="path">
              {label}
            </NavLink>
            {hasNew && newEvents[hasNew] && <span className="red-dot">New!</span>}
          </React.Fragment>
        ))}
      </div>
    </Router>
  )
}

export default NavbarLinks
